<template>
  <div  v-animate-css="{
        classes: 'fadeIn',
        duration: 1000,
      }">
    <div class="text-center">
      <h4>{{question.order}}. {{ question.title }}</h4>
      <p>{{ question.description }}</p>
    </div>
    <div class="my-5">
      <div class="form-group wet-form">
        <textarea
          name=""
          maxlength="200"
          v-model="result"
          @input="handleText($event.target.value)"
          :placeholder="question.options.placeholder"
          id=""
          cols="30"
          style="width: 100%"
          rows="10"></textarea>
      </div>
      <p><span style="color: #23af4d"> {{ ruleText() }}</span> <span :class="{'text-danger': result.length === 200}">{{ result.length }}</span> / 200</p>
    </div>
    <div class=" buttons row mt-5 ">
      <div class="col-6">
        <button class="btn btn-outline-success w-100 controls-button" @click="handlePrev">
          {{ $store.getters.getPrevText }}
        </button>
      </div>
      <div class="col-6">
        <button class="btn btn-outline-success w-100 controls-button" :disabled="handleValidation" @click="handleNext">
          {{$store.getters.getNextText}}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import {descriptionData} from "../../utils/questionsInitialData";
import store from "../../store";
import Vue from 'vue';
import VAnimateCss from 'v-animate-css';

Vue.use(VAnimateCss);

export default {
  name: "Description",
  components: {},
  props: {},
  data() {
    return {
      question: descriptionData,
      result: '',
    }
  },
  mounted() {
  },
  computed: {
    handleValidation() {
      return !this.result
    }
  },
  watch: {
    '$store.state.currentQuestion': function () {
      this.question = store.state.currentQuestion;
      if (store.state.currentQuestion.result.answer !== null) {
        this.result = store.state.currentQuestion.result.answer
      }
    }
  },
  methods: {
    ruleText() {
      const language = localStorage.language;
      if (language === 'ES') {
        return 'Maximo de caracteres:'
      } else {
        return "Maximum characters:"
      }
    },
    handleText(value) {
      this.result = value;
    },
    async handleNext() {
      const data = {
        idQuestion: store.getters.getCurrentQuestion._id,
        orderQuestion: store.getters.getCurrentQuestion.order,
        section: 'valorization',
        idUser: JSON.parse(localStorage.userData)._id,
        email: JSON.parse(localStorage.userData).email,
        answer: this.result
      }
      this.$emit('sendQuestion', data);
    },
    handlePrev() {
      this.$emit('goPrev');
    },
    getQuestionData() {
      if (store.state.currentQuestion.type === 'multipleWithImages') {
        this.question = store.state.currentQuestion;
      }
    },
  },

}
</script>

<style scoped lang="scss">
.icon {
  fill: #23AF4D;
}

.controls-button {
  &:hover {
    .icon   {
      fill: white ;
    }
  }
}

.buttons {
  button {
    margin: 0 0.5rem;

    &:disabled {
      filter: grayscale(100%);
      opacity: 0.6;
      pointer-events: none;
    }
  }
}

.input {
  width: 100%;
}

.label {
  width: auto ;
}

textarea {
  border: 2px solid #23af4d !important;
}


@media (min-width: 768px) {
  .input {
    width: 50%;
  }
  .label {
    width: 10rem ;
  }
}
</style>
